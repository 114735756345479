import ButtonComponent from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

import "./styles.css";

const Button = ({ className = "", children, loading, disabled, ...rest }) => {
  return (
    <ButtonComponent
      {...rest}
      disabled={loading || disabled}
      className={className}
    >
      {children}
      {loading && <Spinner animation="border" className="button-loader" />}
    </ButtonComponent>
  );
};

export default Button;
