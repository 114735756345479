import { useState, useEffect, Fragment } from "react";
import { pdf } from "@react-pdf/renderer";
import Table from "react-bootstrap/Table";
import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import DatePicker from "react-datepicker";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Form from "react-bootstrap/Form";
import chunk from "lodash.chunk";

import PDFReceipt from "../../Components/PDFReceipt";
import PDFStatement from "../../Components/PDFStatement";
import Button from "../../Components/Button";
import AddDetails from "../AddDetails";
import { Axios } from "../../api/axios";
import { getSimplifiedError } from "../../utils/error";
import { getFormattedDate } from "../../utils/utility";
import downloadIcon from "../../assets/downloadIcon.svg";
import "./styles.css";

const Dashboard = () => {
  const date = new Date();
  const initialFirstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const initialLastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const [startDate, setStartDate] = useState(initialFirstDay);
  const [endDate, setEndDate] = useState(initialLastDay);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [error, setError] = useState("");

  const [type, setType] = useState("credit");
  const [showAddDetails, setShowAddDetails] = useState(false);
  const [addedTransaction, setAddedTransaction] = useState({ _id: "" });
  const [creditData, setCreditData] = useState([]);
  const [debitData, setDebitData] = useState([]);

  const [paginatedData, setPaginatedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [perPageRecords, setPerPageRecords] = useState(10);
  const [selectedId, setSelectedId] = useState(null);
  const [currentDetails, setCurrentDetails] = useState(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    getTransactionData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  useEffect(() => {
    if (addedTransaction._id) {
      setAddedTransaction({ _id: "" });
      setType(addedTransaction.type === "credit" ? "credit" : "debit");
      const prevData =
        addedTransaction.type === "credit" ? creditData : debitData;
      const data = [addedTransaction, ...prevData].map((d, i) => ({
        ...d,
        index: i + 1,
      }));
      setCurrentPage(0);
      setPaginatedData(chunk(data, perPageRecords));
      addedTransaction.type === "credit"
        ? setCreditData(data)
        : setDebitData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedTransaction]);

  const getTransactionData = () => {
    const arr = ["credit", "debit"];
    const types = type === "credit" ? arr : arr.reverse();
    types.forEach(async (typeName) => {
      try {
        setLoading(true);
        const from = getFormattedDate(startDate);
        const to = getFormattedDate(endDate);
        const res = await Axios.get(
          `/transaction?type=${typeName}&from=${from}&to=${to}`
        );
        if (res.status === 200) {
          setError("");
          const data = res.data.map((d, i) => ({ ...d, index: i + 1 }));
          typeName === "credit" ? setCreditData(data) : setDebitData(data);
          if (typeName === type) {
            setPaginatedData(chunk(data, perPageRecords));
          }
        }
      } catch (e) {
        setError(getSimplifiedError(e));
      }
      setLoading(false);
    });
  };

  const handleTypeClick = (type) => () => {
    setCurrentPage(0);
    setType(type);
    setPaginatedData(
      chunk(type === "credit" ? creditData : debitData, perPageRecords)
    );
  };

  const handlePerPageRecordsChange = ({ target: { value } }) => {
    setPaginatedData(chunk(type === "credit" ? creditData : debitData, value));
    setCurrentPage(0);
    setPerPageRecords(value);
  };

  const handlePrev = () => {
    setCurrentPage((prev) => prev - 1);
  };

  const handleNext = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const displayPaginationNumbers = () => {
    if (paginatedData.length) {
      const start = paginatedData[currentPage][0].index;
      const end =
        paginatedData[currentPage][paginatedData[currentPage].length - 1].index;
      const total = type === "credit" ? creditData.length : debitData.length;
      return start + "-" + end + " of " + total;
    }
  };

  const downloadPDFReceipt = (t) => async () => {
    if (downloading) return false;
    setDownloading(true);
    const blob = await pdf(<PDFReceipt transaction={t} />).toBlob();
    setDownloading(false);
    openInNewTab(blob);
    // saveAs(blob, "invoice.pdf");
  };

  const openInNewTab = (blob) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = url;
    // a.download = 'receipt.pdf';
    a.target = '_blank';
    a.click();
  }

  const handleViewStatementClick = async () => {
    const data = type === "credit" ? creditData : debitData;
    const blob = await pdf(<PDFStatement type={type} data={data} />).toBlob();
    // saveAs(blob, "statement.pdf");
    openInNewTab(blob);
  };

  const handleDelete = (id) => {
    setSelectedId(id);
    toggleModal();
  }

  const handleEdit = (id, data) => {
    handleShow();
    setSelectedId(id);
    setCurrentDetails(data);
  }

  const toggleModal = () => {
    setShow(!show);
  }

  const handleDeleteClick = async () => {
    try{
      setDeleteLoading(true);
      await Axios.delete(`/transaction/${selectedId}`);
      setSelectedId(null);
    } catch (e) {
      console.log('error', e);
    } finally {
      setDeleteLoading(false);
      toggleModal();
      getTransactionData();
    }
  }

  const handleClose = () => {
    setShowAddDetails(false);
    setCurrentDetails(null);
  };

  const handleShow = () => setShowAddDetails(true);

  return (
    <div className="dashboard-container">
      <AddDetails
        show={showAddDetails}
        handleClose={handleClose}
        setAddedTransaction={setAddedTransaction}
        currentDetails={currentDetails}
        getTransactionData={getTransactionData}
      />
      <Modal
          show={show}
          onHide={toggleModal}
          backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Please Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this item?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={toggleModal}>
            Cancel
          </Button>
          <Button variant="primary" loading={deleteLoading} onClick={handleDeleteClick}>Okay</Button>
        </Modal.Footer>
      </Modal>
      <div className="header-wrapper">
        <Button className="add-button" onClick={handleShow}>
          Add Entry
        </Button>
        <div className="filter-wrapper">
          <DatePicker
            dateFormat="dd/MM/yyyy"
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
          />
          to
          <DatePicker
            dateFormat="dd/MM/yyyy"
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
          />
          <ButtonGroup toggle className="button-group">
            <Button
              onClick={handleTypeClick("credit")}
              className={type === "credit" ? "" : "inactive-button"}
            >
              Credit
            </Button>
            <Button
              onClick={handleTypeClick("debit")}
              className={type === "debit" ? "" : "inactive-button"}
            >
              Debit
            </Button>
          </ButtonGroup>
        </div>
      </div>
      {loading ? (
        <Spinner animation="border" className="main-loader" />
      ) : (
        <Fragment>
          {error && <Alert variant="danger">{error}</Alert>}
          <Table striped bordered responsive className="display-table">
            <thead>
              <tr>
                <th className="header-center">#</th>
                <th>Name</th>
                <th>Amount</th>
                <th className="header-center">Date</th>
                {type === "credit" ? (
                  <th className="header-center">Receipt</th>
                ) : (
                  <th className="header-center">Description</th>
                )}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData[currentPage] &&
                !!paginatedData[currentPage].length ? (
                paginatedData[currentPage].map((t) => (
                  <tr key={t._id}>
                    <td align="center">{t.index}</td>
                    <td>{t.firstName + " " + t.lastName}</td>
                    <td>{t.amount.toFixed(2)}</td>
                    <td align="center">
                      {getFormattedDate(new Date(t.createdAt), "display")}
                    </td>
                    {type === "credit" ? (
                      <td align="center">
                        <img
                          src={downloadIcon}
                          alt="icon"
                          height={25}
                          className="pdf-icon"
                          onClick={downloadPDFReceipt(t)}
                        />
                      </td>
                    ) : (
                      <td align="center">{t.description || 'N/A'}</td>
                    )}
                    <td>
                      <Button
                          variant="outline-info mr-2"
                          onClick={() => handleEdit(t._id, t)}
                      >
                        Edit
                      </Button>
                      <Button
                          variant="outline-danger"
                          onClick={() => handleDelete(t._id)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6} align="center">
                    No transactions found.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          <div className="footer-wrapper">
            <Button className="add-button" onClick={handleViewStatementClick}>
              Download Statement
            </Button>
            <div className="pagination-wrapper">
              <div className="pagination-text">Rows per page:</div>
              <Form.Control
                as="select"
                custom
                name="perPageRecords"
                value={perPageRecords}
                onChange={handlePerPageRecordsChange}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </Form.Control>
              <div className="pagination-text">
                {displayPaginationNumbers()}
              </div>
              <div>
                <Button
                  className="pagination-button"
                  disabled={currentPage === 0}
                  onClick={handlePrev}
                >
                  Prev
                </Button>
                <Button
                  className="pagination-button"
                  disabled={
                    paginatedData.length
                      ? currentPage === paginatedData.length - 1
                      : true
                  }
                  onClick={handleNext}
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default Dashboard;
