import { useState } from "react";
import { useHistory } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

import Button from "../../Components/Button";
import { Axios } from "../../api/axios";
import { getSimplifiedError } from "../../utils/error";
import "./styles.css";

const Login = () => {
  const history = useHistory();

  const [loginDetails, setLoginDetails] = useState({ email: "", password: "" });
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleChange = ({ target: { name, value } }) => {
    setLoginDetails({ ...loginDetails, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() === true) {
      const { email, password } = loginDetails;
      try {
        setLoading(true);
        const { status, data } = await Axios.post("/login", {
          username: email,
          password: password,
        });
        if (status === 200 && data.token) {
          setError("");
          localStorage.setItem("token", data.token);
          localStorage.setItem("user", JSON.stringify(data.user));
          history.push("/");
        }
      } catch (e) {
        setError(getSimplifiedError(e));
      }
      setLoading(false);
    } else {
      setValidated(true);
    }
  };

  return (
    <Container className="login-wrapper">
      <Form
        className="login-form"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <h2 className="title">Log in</h2>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form.Group>
          <Form.Label>Email address</Form.Label>
          <Form.Control
            required
            type="email"
            placeholder="Enter email"
            name="email"
            value={loginDetails.email}
            onChange={handleChange}
          />
          <Form.Control.Feedback type="invalid">
            Please provide valid email.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label>Password</Form.Label>
          <Form.Control
            required
            type="password"
            placeholder="Password"
            name="password"
            value={loginDetails.password}
            onChange={handleChange}
          />
          {!loginDetails.password && (
            <Form.Control.Feedback type="invalid">
              Please provide password.
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Button
          type="submit"
          className="btn btn-dark btn-lg btn-block"
          loading={loading}
          disabled={loading}
        >
          Login
        </Button>
      </Form>
    </Container>
  );
};

export default Login;
