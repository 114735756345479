import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

import { getFormattedDate } from "../../utils/utility";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "black",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
  },
  index: {
    width: "10%",
    textAlign: "center",
    borderRightColor: "black",
    borderRightWidth: 1,
  },
  name: {
    width: "50%",
    textAlign: "left",
    paddingLeft: 8,
    borderRightColor: "black",
    borderRightWidth: 1,
  },
  debitName: {
    width: "40%",
    textAlign: "left",
    paddingLeft: 8,
    borderRightColor: "black",
    borderRightWidth: 1,
  },
  amount: {
    width: "20%",
    textAlign: "left",
    paddingLeft: 8,
    borderRightColor: "black",
    borderRightWidth: 1,
  },
  date: {
    width: "20%",
    textAlign: "left",
    paddingLeft: 8,
  },
  dateAmount: {
    width: "20%",
    textAlign: "left",
    paddingLeft: 8,
    borderRightColor: "black",
    borderRightWidth: 1,
  },
  chequeNo: {
    width: "20%",
    textAlign: "right",
    paddingRight: 8,
  },
});

const InvoiceTableRow = ({ type, data }) => {
  const rows = data?.map((t) => (
    <View style={styles.row} key={t._id}>
      <Text style={styles.index}>{t.index}</Text>
      <Text style={type === "credit" ? styles.name : styles.debitName}>
        {t.firstName + " " + t.lastName}
      </Text>
      <Text style={styles.amount}>{t.amount.toFixed(2)}</Text>
      <Text style={type === "credit" ? styles.date : styles.dateAmount}>
        {getFormattedDate(new Date(t.createdAt), "display")}
      </Text>
      {type === "debit" && (
        <Text style={styles.chequeNo}>{t.amount.toFixed(2)}</Text>
      )}
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

export default InvoiceTableRow;
