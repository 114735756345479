import { useHistory } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Form from "react-bootstrap/Form";

import Button from "../Button";

const NavBar = () => {
  const history = useHistory();

  const handleClick = () => {
    localStorage.removeItem("token");
    history.push("/login");
  };

  return (
    <Navbar bg="primary" variant="dark">
      <Navbar.Brand className="mr-auto">Dashboard</Navbar.Brand>
      <Form inline>
        <Button variant="outline-light" onClick={handleClick}>
          Logout
        </Button>
      </Form>
    </Navbar>
  );
};

export default NavBar;
