import {
  Page,
  View,
  Text,
  Image,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";

import InvoiceTableRow from "./InvoiceTableRow";
import logo from "../../assets/logo.jpeg";
import LogoTitle from "../../assets/colorlogoTectPng.png";

// Create styles
const styles = StyleSheet.create({
  // page: {
  //   fontSize: 11,
  //   padding: 30,
  //   lineHeight: 1.5,
  //   flexDirection: "column",
  // },
  section: {
    margin: 20,
    padding: 20,
    flexGrow: 1,
    width: "100%",
    paddingTop:0,
  },
  header: {
    display:'flex',
    alignItems: 'center',
    textAlign: 'center',
    marginRight: 30,
    paddingBottom:8,
    borderBottom:'1px solid #dee2e6'
  },
  logo: {
    width: 70,
    height: "auto",
    display: "inline-block",
  },
  logoText: {
    width: 110,
    height: "auto",
    display: "inline-block",
  },
  title: {
    margin: "30px 0 20px",
    fontSize: 18,
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "black",
    width: "95%"
  },
  tableHeader: {
    flexDirection: "row",
    borderBottomColor: "black",
    borderBottomWidth: 2,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
  },
  index: {
    width: "10%",
    textAlign: "center",
    borderRightColor: "black",
    borderRightWidth: 1,
  },
  name: {
    width: "50%",
    textAlign: "left",
    paddingLeft: 8,
    borderRightColor: "black",
    borderRightWidth: 1,
  },
  debitName: {
    width: "40%",
    textAlign: "left",
    paddingLeft: 8,
    borderRightColor: "black",
    borderRightWidth: 1,
  },
  amount: {
    width: "20%",
    textAlign: "left",
    paddingLeft: 8,
    borderRightColor: "black",
    borderRightWidth: 1,
  },
  date: {
    width: "20%",
    textAlign: "left",
    paddingLeft: 8,
  },
  dateAmount: {
    width: "20%",
    textAlign: "left",
    paddingLeft: 8,
    borderRightColor: "black",
    borderRightWidth: 1,
  },
  chequeNo: {
    width: "20%",
    textAlign: "right",
    paddingRight: 8,
  },
});

// Create Document Component
const PDFStatement = ({ type, data }) => {
  return (
    <Document>
      <Page size="A4">
        <View style={styles.section}>
          <View style={styles.header}>
            <Image src={logo} style={styles.logo} />
            <Image src={LogoTitle} style={styles.logoText} />
          </View>
          <View style={styles.tableContainer}>
            <View style={styles.tableHeader}>
              <Text style={styles.index}>#</Text>
              <Text style={type === "credit" ? styles.name : styles.debitName}>
                Name
              </Text>
              <Text style={styles.amount}>Amount</Text>
              <Text style={type === "credit" ? styles.date : styles.dateAmount}>
                Date
              </Text>
              {type === "debit" && (
                  <Text style={styles.chequeNo}>Cheque Number</Text>
              )}
            </View>
            <InvoiceTableRow type={type} data={data} />
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PDFStatement;
