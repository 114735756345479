import {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

import Button from "../../Components/Button";
import { Axios } from "../../api/axios";
import { getSimplifiedError } from "../../utils/error";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.css";

const initValues = {
  firstName: "",
  lastName: "",
  amount: 0,
  type: "credit",
};

const AddDetails = ({ show, handleClose, setAddedTransaction, currentDetails, getTransactionData }) => {
  const [details, setDetails] = useState(initValues);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if(currentDetails) {
      setDetails({ ...initValues, ...currentDetails });
    }
  }, [currentDetails]);

  const closeDialog = () => {
    setDetails(initValues);
    handleClose();
  };

  const handleChange = ({ target: { name, value } }) => {
    setDetails({ ...details, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (details.amount <= 0) {
      setError("Please provide valid amount.");
      return;
    }
    setError("");

    const payload = {
      firstName: details.firstName,
      lastName: details.lastName,
      amount: details.amount,
      type: details.type,
    }
    try {
      setLoading(true);
      if(currentDetails) {
        const { status } = await Axios.put(`/transaction/${currentDetails._id}`, payload);
        if (status === 200) {
          setError("");
          setDetails(initValues);
          closeDialog();
          getTransactionData();
        }
      } else {
        const { status, data } = await Axios.post("/transaction", payload);
        if (status === 200) {
          setError("");
          setDetails(initValues);
          setAddedTransaction(data);
          closeDialog();
        }
      }
    } catch (e) {
      setError(getSimplifiedError(e));
    }
    setLoading(false);
  };

  return (
    <Modal
      show={show}
      onHide={closeDialog}
      backdrop={loading ? "static" : true}
    >
      <Form className="add-details-wrapper" onSubmit={handleSubmit}>
        <h2 className="title">Add Details</h2>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form.Group>
          <Form.Label>First Name</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Enter first name"
            name="firstName"
            value={details.firstName}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Enter last name"
            name="lastName"
            value={details.lastName}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group>
          <Form.Check
            inline
            type="radio"
            label="Credit"
            name="type"
            value="credit"
            checked={details.type === "credit"}
            onChange={handleChange}
          />
          <Form.Check
            inline
            type="radio"
            label="Debit"
            name="type"
            value="debit"
            checked={details.type === "debit"}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Amount</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter Amount"
            name="amount"
            value={details.amount}
            onChange={handleChange}
          />
        </Form.Group>

        {details.type === 'debit' && <Form.Group>
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            placeholder="Enter Description"
            name="description"
            value={details.description}
            onChange={handleChange}
          />
        </Form.Group>}

        <Button
          type="submit"
          className="btn btn-lg btn-block"
          loading={loading}
          disabled={loading}
        >
          {currentDetails ? 'Edit' : 'Add'}
        </Button>
      </Form>
    </Modal>
  );
};

export default AddDetails;
