import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import { PrivateRoute, PublicRoute } from "./routes";
import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";

const Routes = () => {
  return (
    <Router>
      <Switch>
        <PrivateRoute path="/" exact component={Dashboard} />
        <PublicRoute path="/login" exact component={Login} />
        {/* <Route component={PageNotFound} /> */}
      </Switch>
    </Router>
  );
};

export default Routes;
