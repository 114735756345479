import React from "react";
import { Redirect, Route } from "react-router-dom";
import Navbar from "../Components/Navbar";

import { isLoggedIn } from "../utils/utility";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        !isLoggedIn() ? (
          <Redirect to="/login" />
        ) : (
          <div>
            <Navbar />
            <Component {...props} />
          </div>
        )
      }
    />
  );
};

export const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn() ? <Redirect to="/" /> : <Component {...props} />
      }
    />
  );
};
