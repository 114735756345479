import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const Axios = axios.create({
  baseURL,
  timeout: 80000,
});

Axios.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");
    if (token) config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  (error) => Promise.reject(error)
);

export { Axios };
