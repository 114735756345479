import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Link,
} from "@react-pdf/renderer";
import logo from "../../assets/logo.jpeg";
import LogoTitle from '../../assets/colorlogoTectPng.png'

import { getFormattedDate, convertNumberToWords } from "../../utils/utility";

// Create styles
const styles = StyleSheet.create({
  section: {
    margin: 20,
    padding: 20,
    flexGrow: 1,
    width: "100%",
    paddingTop:0,
  },
  header: {
    display:'flex',
    alignItems: 'center',
    textAlign: 'center',
    marginRight: 30,
    paddingBottom:8,
    borderBottom:'1px solid #dee2e6'
  },
  logo: {
    width: 70,
    height: "auto",
    display: "inline-block",
  },
  logoText: {
    width: 110,
    height: "auto",
    display: "inline-block",
  },
  title: {
    margin: "5px 0px 0px 0",
    fontSize: 18,
    textAlign: 'center',
  },
  rightSide: {
    display: "flex",
    alignItems: "flex-end",
  },
  subHeader:{
    display:'flex',
    alignItems: "center",
    flexDirection: 'row',
    justifyContent:'space-between',
  },
  date: {
    fontSize: 12,
    margin: "20px 30px 40px 0",
  },
  labelValueWrapper: {
    display: "flex",
    flexDirection: "row",
    marginBottom: '5px',
  },
  label: {
    fontSize: 14,
  },
  link:{
    color:'#005cc5',
  },
  center:{
    width:'100%',
  },
  valueWrapper: {
    marginRight: 40,
    marginBottom: 10,
    flex: 1,
  },
  value: {
    fontSize: 14,
    padding: "0 10px 0 3px",
    borderBottom: '1px solid #dee2e6'
  },
  noteWrapper:{
    fontSize: 10,
    width:'95%',
    marginTop: 70,
  },
});

const dt = new Date();

const year  = dt.getFullYear();
const month = (dt.getMonth() + 1).toString().padStart(2, "0");
const day   = dt.getDate().toString().padStart(2, "0");

// Create Document Component
const PDFReceipt = ({
                      transaction: { firstName, lastName, email, amount, type, createdAt,index },
                    }) => (
    <Document>
      <Page size="A4">
        <View style={styles.section}>
          <View style={styles.header}>
            <Image src={logo} style={styles.logo} />
            <Image src={LogoTitle} style={styles.logoText} />
          </View>

          <View style={styles.subHeader}>
            <View style={styles.rightSide}>
              <Text style={styles.date}>
                Invoice number: {day}{month}{String(year).slice(-2)}{index}
              </Text>
            </View>

            <View style={styles.rightSide}>
              <Text style={styles.date}>
                Date: {getFormattedDate(new Date(createdAt), "display")}
              </Text>
            </View>
          </View>

          <View style={styles.labelValueWrapper}>
            <Text style={styles.label}>Received from: </Text>
            <View style={styles.valueWrapper}>
              <Text style={styles.value}>{firstName + " " + lastName}</Text>
            </View>
          </View>

          <View style={styles.labelValueWrapper}>
            <Text style={styles.label}>Rupees in number: </Text>
            <View style={styles.valueWrapper}>
              <Text style={styles.value}>
                {amount.toFixed(2)} /-
              </Text>
            </View>
          </View>

          <View style={styles.labelValueWrapper}>
            <Text style={styles.label}>Rupees in word: </Text>
            <View style={styles.valueWrapper}>
              <Text style={styles.value}>
                {convertNumberToWords(amount) + "Only"}
              </Text>
            </View>
          </View>

          <View style={styles.noteWrapper}>
            <Text>This digitally generated payment receipt, in case of any query drop an email on <Link style={styles.link}>bndadmin2021@gmail.com</Link></Text>
          </View>
        </View>
      </Page>
    </Document>
);

export default PDFReceipt;